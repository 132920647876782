var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column py-4"},[_c('div',{staticClass:"header-top"},[_c('h2',{staticClass:"view-title"},[_vm._v("Competitor History")]),_c('v-text-field',{staticClass:"search",attrs:{"label":"Search","prepend-icon":"mdi-magnify","clearable":""},on:{"click:clear":_vm.clearSearch},model:{value:(_vm.search.search),callback:function ($$v) {_vm.$set(_vm.search, "search", $$v)},expression:"search.search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"sort-by":"id","sort-desc":_vm.sort_desc,"footer-props":{ 'items-per-page-options': _vm.items_per_page },"options":_vm.options,"server-items-length":_vm.total,"mobile-breakpoint":"0"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.keyword",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"domain-list"},[(item.keyword)?_c('a',{staticClass:"col-keyword ",attrs:{"href":_vm.googleLink(item.keyword),"target":"_blank"}},[_vm._v(_vm._s(item.keyword))]):_vm._e()])]}},{key:"item.comp_urls",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"domain-list"},_vm._l((item.comp_urls),function(url,index){return _c('a',{key:index,staticClass:"url-domain",attrs:{"href":url,"target":"_blank"}},[_vm._v(_vm._s(url))])}),0)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"pr-2 text--darken-3 text--secondary",on:{"click":function($event){return _vm.goToAction(item)}}},[_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cont-actions"},[(item.status === 'completed')?_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.goToPath('/serp-analysis/report/' + item.id)}}},[_vm._v(" Report ")]):_vm._e(),(item.status === 'failed')?_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){return _vm.goToPath('/serp-analysis/await/' + item.id)}}},[_vm._v(" Failed ")]):_vm._e(),(item.status === 'needs_review')?_c('v-btn',{attrs:{"color":"warning","outlined":""},on:{"click":function($event){return _vm.goToPath('/serp-analysis/await/' + item.id)}}},[_vm._v(" Needs Review ")]):_vm._e(),_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.showDialog(item)}}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('dialog-delete-report',{attrs:{"show":_vm.dialog_delete.show,"item":_vm.dialog_delete.item,"title":"Are you sure to delete this report?","description":"This process is irreversible, and can no longer be recovered, would you like to continue?","deleteReport":_vm.deleteReport},on:{"update:show":function($event){return _vm.$set(_vm.dialog_delete, "show", $event)}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }