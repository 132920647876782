<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ title }}
        </v-card-title>
        <v-card-text>{{ description }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            outlined
            @click="closeDialog()"
          >
            Close
          </v-btn>
          <v-btn
            color="error"
            text
            outlined
            @click="deleteDialog()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    props: {
      show: Boolean,
      title: String,
      description: String,
      deleteReport: { type: Function },
      item: Object,
    },
    data () {
      return {}
    },
    methods: {
      closeDialog(){
        this.$emit('update:show', false);
      },
      deleteDialog(){
        this.$emit('update:show', false);
        this.deleteReport();
      },
    },
  }
</script>
